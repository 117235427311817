.carousel-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.image-section {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 1;
}

.text-section {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  color: #fff;
  text-align: left;
  z-index: 2;
  padding: 20px;
  max-width: 50%;
}

.text-section h1,
.text-section h2,
.text-section p {
  margin-bottom: 15px;
}

.buttons {
  margin-top: 20px;
}

.btn-start,
.btn-contact {
  margin-right: 10px;
}
.image-head{
  font-family: "heading";
  letter-spacing: 5px;

}
.image-head2{
text-transform: uppercase;
color: var(--primary-color);
}
@media (max-width: 768px) {
  .text-section {
    left: 5%;
    max-width: 100%;
    padding: 10px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-start,
  .btn-contact {
    margin-bottom: 10px;
  }
}
