
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

.hero-section {
  position: relative;
  width: 100%;
  height: 60vh;
  background: url("https://images.pexels.com/photos/10518845/pexels-photo-10518845.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2")
    no-repeat center center/cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.473);
}

.content {
  position: relative;
  z-index: 2;
  color: white;
  padding: 0 1rem;
}

.content h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.content p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-family: "heading", sans-serif;
  letter-spacing: 3px;
  color: var(--primary-color);
}

.btn-outline-light {
  border-color: var(--primary-color);
  color: #fff;
  padding: 0.5rem 2rem;
  font-size: 0.7rem;
  letter-spacing: 1px;
  font-weight: 600;
  border-radius: 0%;
}

.btn-outline-light:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-section {
    height: 50vh;
  }

  .content h1 {
    font-size: 1.5rem;
  }

  .content p {
    font-size: 1rem;
  }

  .btn-outline-light {
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .hero-section {
    height: 40vh;
  }

  .content h1 {
    font-size: 1.2rem;
  }

  .content p {
    font-size: 0.9rem;
  }

  .btn-outline-light {
    padding: 0.4rem 1rem;
    font-size: 0.7rem;
  }
}
