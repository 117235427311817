@keyframes square-animation {
    0% { left: 0; top: 0; }
    10.5% { left: 0; top: 0; }
    12.5% { left: 32px; top: 0; }
    23% { left: 32px; top: 0; }
    25% { left: 64px; top: 0; }
    35.5% { left: 64px; top: 0; }
    37.5% { left: 64px; top: 32px; }
    48% { left: 64px; top: 32px; }
    50% { left: 32px; top: 32px; }
    60.5% { left: 32px; top: 32px; }
    62.5% { left: 32px; top: 64px; }
    73% { left: 32px; top: 64px; }
    75% { left: 0; top: 64px; }
    85.5% { left: 0; top: 64px; }
    87.5% { left: 0; top: 32px; }
    98% { left: 0; top: 32px; }
    100% { left: 0; top: 0; }
  }
  
  .loader {
    position: fixed; /* Fixed to the viewport */
    top: 0;
    left: 0;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex; /* Flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background: #171717; /* Semi-transparent background */
    z-index: 9999; /* Ensure it’s on top */
  }
  
  .loader-container {
    position: relative; /* For positioning squares */
    width: 96px; /* Width of the container */
    height: 96px; /* Height of the container */
  }
  
  .loader-square {
    position: absolute; /* Absolute positioning within container */
    width: 28px;
    height: 28px;
    background: var(--primary-color); /* Use your primary color */
    animation: square-animation 8s ease-in-out infinite both; /* Adjusted animation duration */
  }
  
  .loader-square:nth-of-type(1) { animation-delay: 0s; }
  .loader-square:nth-of-type(2) { animation-delay: -1.1428571429s; }
  .loader-square:nth-of-type(3) { animation-delay: -2.2857142857s; }
  .loader-square:nth-of-type(4) { animation-delay: -3.4285714286s; }
  .loader-square:nth-of-type(5) { animation-delay: -4.5714285714s; }
  .loader-square:nth-of-type(6) { animation-delay: -5.7142857143s; }
  .loader-square:nth-of-type(7) { animation-delay: -6.8571428571s; }
  